import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { Dialog, Transition } from '@headlessui/react'
import ReactImageAppear from 'react-image-appear';
import { shortenAddress } from '@usedapp/core'
// Assets
import loading from '../assets/loading.gif'
import leaderboardEllipse from '../assets/leaderboard-ellipse.svg'
import leaderboardLeft from '../assets/leaderboard-left.png'
import leaderboardRight from '../assets/leaderboard-right.png'

function Leaderboard() {
  const [towers, setTowers] = useState([])
  const towersUrl = 'https://api.monsterblocks.io/towers'

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedTower, setSelectedTower] = useState(null)

  useEffect(() => {
    axios.get(towersUrl).then(response => {
      setTowers(response.data)
    })
  }, []);

  const selectTower = (tower) => {
    setSelectedTower(tower)
    setIsModalOpen(true)
  }

  return (
    <div className="container mx-auto mt-8 sm:mt-0 mb-24">
        <div>
          <div className="py-8 px-8 lg:px-32 grid grid-cols-4">
            <div className="-ml-10 sm:ml-0 -mt-32 sm:mt-0 flex items-center justify-center">
              <img src={leaderboardLeft} alt="Leaderboard Left" className="w-32 md:w-48 object-contain" />
            </div>
            <div className="col-span-2 flex items-center justify-center">
              <h1 className="font-cursive text-center uppercase text-5xl">
                  Tower Leaderboard
              </h1>
            </div>
            <div className="-mr-10 sm:mr-0 -mt-32 sm:mt-0 flex items-center justify-center">
              <img src={leaderboardRight} alt="Leaderboard Right" className="w-32 md:w-48 object-contain" />
            </div>
          </div>
          <div>
            <div className="my-1 py-3 grid grid-cols-7 sm:grid-cols-9 bg-gray-100 w-full text-gray-600 font-bold text-lg sm:text-xl shadow-md rounded-lg">
              <div className="col-span-1 px-2 sm:px-4">
                Rank
              </div>
              <div className="col-span-2 px-2">
                Height
              </div>
              <div className="col-span-2 px-2">
                Name
              </div>
              <div className="col-span-2 px-2">
                Owner
              </div>
              <div className="hidden sm:flex col-span-2 px-2">
              </div>
            </div>
            {towers.map((tower, index) => {
              return (
                <div key={tower.token.toString()} className="my-1 py-2 grid grid-cols-7 sm:grid-cols-9 bg-white w-full text-gray-800 font-bold text-lg shadow-md rounded-lg">
                  <div className="col-span-1 px-4 flex items-center">
                    <img src={leaderboardEllipse} alt="Ellipse" className={'z-0 w-20 object-contain transform rotate-'.concat((index % 2)*180)} />
                    <span className="-ml-10 md:-ml-12 lg:-ml-20 w-20 font-cursive text-3xl font-bold text-center items-center z-10">
                      {tower.rank}
                    </span>
                  </div>
                  <div className="col-span-2 px-2 h-full flex items-center">
                    {tower.height} Monster Blocks
                  </div>
                  <div className="col-span-2 px-2 h-full flex items-center">
                    {tower.name}
                  </div>
                  <div className="col-span-2 px-2 h-full flex items-center">
                    <a href={'https://opensea.io/'.concat(tower.owner).concat('/monster-blocks')} target="_blank" rel="noreferrer"
                       className="underline text-green-600 hover:text-green-700">
                      {tower.owner &&
                        tower.owner.substring(tower.owner.length - 4) === ".eth" ? tower.owner : shortenAddress(tower.owner) }
                    </a>
                  </div>
                  <div className="hidden sm:flex col-span-2 px-2 h-full items-center">
                    <div className="grid grid-cols-2 gap-2 py-6 sm:py-2 xl:py-6 lg:px-8">
                      <button onClick={() => selectTower(tower)}
                         className="col-span-2 xl:col-span-1 text-xl bg-green-500 text-white font-cursive hover:text-gray-100 hover:bg-green-600 py-2 px-4 rounded-lg">
                        Preview
                      </button>
                      <a href={'https://opensea.io/assets/0xa56a4f2b9807311AC401c6afBa695D3B0C31079d/'.concat(tower.token)} target="_blank" rel="noreferrer"
                         className="col-span-2 xl:col-span-1 text-xl bg-gray-400 text-white font-cursive hover:text-gray-100 hover:bg-gray-500 py-2 px-4 rounded-lg">
                        OpenSea
                      </a>
                    </div>
                  </div>
                  <div className="col-span-7 px-2 pt-4 pb-2 sm:hidden">
                    <div className="flex flex-row items-center gap-4">
                      <button onClick={() => selectTower(tower)}
                         className="w-1/2 text-lg bg-green-500 text-center text-white font-cursive hover:text-gray-100 hover:bg-green-600 py-2 px-4 rounded-lg">
                        Preview
                      </button>
                      <a href={'https://opensea.io/assets/0xa56a4f2b9807311AC401c6afBa695D3B0C31079d/'.concat(tower.token)} target="_blank" rel="noreferrer"
                         className="w-1/2 text-lg bg-gray-400 text-center text-white font-cursive hover:text-gray-100 hover:bg-gray-500 py-2 px-4 rounded-lg">
                        OpenSea
                      </a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

      { selectedTower &&

        <Transition.Root show={isModalOpen} as={Fragment}>
          <Dialog as="div" auto-reopen="true" className="fixed z-10 inset-0 overflow-y-auto" onClose={setIsModalOpen}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                  <ReactImageAppear
                      key={selectedTower.image_url}
                      src={selectedTower.image_url}
                      animation="zoomIn"
                      animationDuration="0ms"
                      className="bg-white"
                      loader={loading}
                      loaderStyle={{ backgroundColor: "white", position: "absolute", top: 100 }}
                      placeholderStyle={{ backgroundColor: "white" }}
                  />
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="outline-none inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-gray-700 bg-gray-200 text-base font-medium text-white hover:bg-gray-300 sm:text-sm"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      }
    </div>
  )
}

export default Leaderboard
