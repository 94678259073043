import React, { Fragment, useState, useEffect } from 'react'
import { useEthers, ChainId, getChainName, useContractFunction } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'
import { ethers } from 'ethers'
import useOwnedTokens from '../hooks/useOwnedTokens'
import useTokenBalance from '../hooks/useTokenBalance'
import { monsterBlockContract, MONSTER_BLOCK_CONTRACT_ADDRESS, MonsterBlockInterface } from '../model/monsterBlockContract'
import { estimateGas } from '../calls/estimateGas'
import { Listbox, Transition } from '@headlessui/react'
import ReactImageAppear from 'react-image-appear';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
// Assets
import loading from '../assets/loading.gif'
import txRejected from '../assets/tx-rejected.png'
import txMining from '../assets/tx-mining.gif'
import txConfirmed from '../assets/tower-builder-animation.gif'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Stackinator() {
    const { account, chainId, library, activateBrowserWallet } = useEthers()
    const [formValid, setFormValid] = useState(true)
    const tokenBalance = useTokenBalance(account)
    const ownedTokens = useOwnedTokens(account)
    const [bottomBlock, setBottomBlock] = useState(null)
    const [topBlock, setTopBlock] = useState(null)
    const [bottomImageSrc, setBottomImageSrc] = useState(null)
    const [topImageSrc, setTopImageSrc] = useState(null)
    const [transactionStatus, setTransactionStatus] = useState('empty')
    const [lastTransaction, setLastTransaction] = useState(null)
    const [transactionErrorMessage, setTransactionErrorMessage] = useState(null)

    const handleFormChange = (bBlock, tBlock) => {
      setFormValid(bBlock !== tBlock)
    }

    const chooseBottomBlock = (block) => {
      setBottomBlock(block)
      setBottomImageSrc("https://storageapi.fleek.co/monster-blocks-bucket/production/".concat(block.id.toString()))
      if (topBlock) {
        handleFormChange(block.id, topBlock.id)
      }
    }

    const chooseTopBlock = (block) => {
      setTopBlock(block)
      setTopImageSrc("https://storageapi.fleek.co/monster-blocks-bucket/production/".concat(block.id.toString()))
      if (bottomBlock) {
        handleFormChange(bottomBlock.id, block.id)
      }
    }

    useEffect(() => {
      if (!bottomBlock && ownedTokens.length > 1) {
        chooseBottomBlock(ownedTokens[0])
      }
      if (!topBlock && ownedTokens.length > 1) {
        chooseTopBlock(ownedTokens[1])
      }
    }, [ownedTokens, bottomBlock, topBlock]);

    const { send, state } = useContractFunction(monsterBlockContract(), 'engageStackinator')

    useEffect(() => {
      if (state && state.status === "Mining") {
        setTransactionStatus('mining')
        setLastTransaction(state.transaction.hash)
      } else if (state && state.status === "Success" && state.transaction.hash === lastTransaction) {
        setTransactionStatus('success')
        setLastTransaction(null)
      } else if (state && (state.status === "Fail" || state.status === "Exception")) {
        setTransactionStatus('failed')
        setLastTransaction(null)
        setTransactionErrorMessage(state.errorMessage)
      }
    }, [state]);

    const resetStack = () => {
      setTransactionStatus('empty')
      setLastTransaction(null)
      setTransactionErrorMessage(null)
    }

    const stackTower = async (bottomBlock: number, topBlock: number) => {
      try {
        const gasEstimation = estimateGas(
          new Contract(MONSTER_BLOCK_CONTRACT_ADDRESS, MonsterBlockInterface, library),
          'engageStackinator',
          [bottomBlock, topBlock, { from: account }],
          1800
        )

        send(bottomBlock, topBlock, {
          gasLimit: gasEstimation
        })
      } catch (e) {
        console.error(e)
      }
    }

    return (
        <div className="container mx-auto">
          { !account &&
            <div className="flex justify-center text-center my-32 px-4 md:px-8 lg:px-16 xl:px-24">
              <div className="flex flex-col border-2 border-yellow-300 rounded-md bg-white">
                <div className="bg-yellow-300 px-16 py-6 mb-4">
                  <span className="font-cursive uppercase text-4xl">
                    The Stackinator
                  </span>
                </div>
                <div>
                  <div className="pb-6">
                    <button onClick={activateBrowserWallet}
                            className="mt-4 text-3xl bg-green-500 text-white font-cursive uppercase hover:text-gray-100 hover:bg-green-600 py-4 px-6 rounded-lg">
                     Connect Wallet
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }

          { account && process.env.REACT_APP_ENVIRONMENT === 'production' && chainId !== ChainId.Mainnet &&
            <div className="flex justify-center text-center my-32 px-4 md:px-8 lg:px-16 xl:px-24">
              <div className="flex flex-col border-2 border-yellow-300 rounded-md bg-white">
                <div className="bg-yellow-300 px-16 py-6 mb-4">
                  <span className="font-cursive uppercase text-4xl">
                    Please Change Networks
                  </span>
                </div>
                <div className="pb-6">
                  This app only functions on mainnet.<br/>
                  You are connected to {getChainName(chainId)}; please change networks.
                </div>
              </div>
            </div>
          }

        { account && (chainId === ChainId.Mainnet || process.env.REACT_APP_ENVIRONMENT !== 'production') && tokenBalance < 2 &&
          <div className="flex justify-center text-center my-32 px-4 md:px-8 lg:px-16 xl:px-24">
            <div className="flex flex-col border-2 border-yellow-300 rounded-md bg-white">
              <div className="bg-yellow-300 px-16 py-6 mb-4">
                <span className="font-cursive uppercase text-4xl">
                  The Stackinator
                </span>
              </div>
              <div className="px-12 pb-6">
                You need at least two Monster Blocks to stack!
              </div>
            </div>
          </div>
        }

        { account && (chainId === ChainId.Mainnet || process.env.REACT_APP_ENVIRONMENT !== 'production') && tokenBalance > 1 &&
          <div className="flex w-full justify-center text-center mt-8 mb-16 px-8 md:px-32 lg:px-40 xl:px-72">
            <div className="flex flex-col w-full border-2 border-yellow-300 rounded-md bg-white">
              <div className="bg-yellow-300 px-16 py-6 mb-4">
                <span className="font-cursive uppercase text-4xl">
                  { transactionStatus === 'mining' && <>Processing...</> }
                  { transactionStatus === 'success' && <>Stacking...</> }
                  { transactionStatus === 'failed' && <>Transaction Rejected</> }
                  { transactionStatus === 'empty' && <>The Stackinator</> }
                </span>
              </div>

              { transactionStatus === 'failed' &&
                <div>
                  <div className="flex flex-col space-y-4">
                    <span>
                      Looks like your transaction was rejected. Please try again!
                    </span>
                    <img src={txRejected} alt="Transaction Rejected" className="h-48 object-contain" />
                    { transactionErrorMessage &&
                      <span className="text-red-800">
                        {transactionErrorMessage}
                      </span>
                    }
                  </div>
                  <div className="pt-4 pb-6">
                    <button onClick={() => resetStack()}
                            className="mt-4 text-3xl bg-green-500 text-white font-cursive hover:text-gray-100 hover:bg-green-600 py-4 px-6 rounded-lg">
                      Try again
                    </button>
                  </div>

                </div>
              }

              { transactionStatus === 'mining' &&
                <div className="pb-6 px-8 sm:px-16">
                  <div className="flex flex-col">
                    <span className="pb-4">
                      A virtual gnome in Ethereum hyperspace is processing your transaction.
                    </span>
                    <span>
                      Please hold the line.
                    </span>
                    <img src={txMining} alt="Transaction Mining" className="py-4 h-48 object-contain" />
                  </div>
                </div>
              }

              { transactionStatus === 'success' &&
                <div className="px-8 sm:px-16">
                  <div className="flex flex-col space-y-4">
                    <span>
                      Huzzah, success!
                    </span>
                    <span>
                      A virtual stonemason is carefully stacking your Monster Blocks atop one another.
                    </span>
                    <span>
                      Please allow <span className="font-bold">a minute or two</span> for your stacked Monster Block to
                      be generated.
                    </span>
                    <img src={txConfirmed} alt="Transaction Confirmed" className="h-64 object-contain" />
                  </div>
                  <div className="grid grid-cols-2 gap-6 py-6 md:px-8">
                    <button onClick={() => window.location.reload(false)}
                            className="col-span-2 lg:col-span-1 text-3xl bg-green-500 text-white font-cursive hover:text-gray-100 hover:bg-green-600 py-4 rounded-lg">
                      Stack more blocks
                    </button>
                    <a href={"https://opensea.io/".concat(account).concat('?search[sortBy]=LAST_TRANSFER_DATE&search[sortAscending]=false')} target="_blank" rel="noreferrer"
                       className="col-span-2 lg:col-span-1 text-3xl bg-gray-400 text-white font-cursive hover:text-gray-100 hover:bg-gray-500 py-4 rounded-lg">
                    View on OpenSea
                    </a>
                  </div>
                </div>
              }

              { transactionStatus === 'empty' &&
              <div className="grid grid-cols-2 gap-8 my-6 px-4 md:px-8">
                <div className="col-span-2">
                  Please select two Monster Blocks to stack. (You can also stack towers together!)
                  The original blocks will be burned, and you will receive a new, merged
                  Monster Tower with a new serial number. Stacking only costs you the gas fee.
                </div>
                <div className="col-span-2">
                  Note: <span className="font-bold">this process is irreversible.</span> Please stack with intention.
                </div>
                <div className="col-span-2 md:col-span-1">
                  <span className="font-cursive uppercase text-2xl">
                    Bottom Block
                  </span>
              { bottomBlock &&
                <Listbox value={bottomBlock} onChange={chooseBottomBlock}>
                  {({ open }) => (
                    <>
                      <div className="mt-1 mb-4 relative">
                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          <span className="block truncate">{bottomBlock.name}</span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            static
                            className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                          >
                            {ownedTokens.map((token) => (
                              <Listbox.Option
                                key={token.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                  )
                                }
                                value={token}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-bold' : 'font-normal', 'block truncate')}>
                                      {token.name}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-indigo-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox> }

                { bottomBlock &&
                  <ReactImageAppear
                      key={bottomImageSrc}
                      src={bottomImageSrc}
                      animation="zoomIn"
                      animationDuration="0ms"
                      className="bg-white"
                      loader={loading}
                      loaderStyle={{ backgroundColor: "white" }}
                      placeholderStyle={{ backgroundColor: "white" }}
                  />
                }
              </div>

              <div className="col-span-2 md:col-span-1">
                <span className="font-cursive uppercase text-2xl">
                  Top Block
                </span>
              { topBlock &&
                <Listbox value={topBlock} onChange={chooseTopBlock} className="mb-8">
                  {({ open }) => (
                    <>
                      <div className="mt-1 mb-4 relative">
                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          <span className="block truncate">{topBlock.name}</span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            static
                            className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                          >
                            {ownedTokens.map((token) => (
                              <Listbox.Option
                                key={token.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                  )
                                }
                                value={token}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-bold' : 'font-normal', 'block truncate')}>
                                      {token.name}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-indigo-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox> }
                { topBlock &&
                  <ReactImageAppear
                      key={topImageSrc}
                      src={topImageSrc}
                      animation="zoomIn"
                      animationDuration="0ms"
                      className="bg-white"
                      loader={loading}
                      loaderStyle={{ backgroundColor: "white" }}
                      placeholderStyle={{ backgroundColor: "white" }}
                  />
                }
                </div>

                <div className="col-span-2 pb-6">
                  <button disabled={!formValid} onClick={() => stackTower(bottomBlock.id, topBlock.id)}
                          className="mt-4 text-2xl bg-green-500 text-white font-cursive hover:text-gray-100 hover:bg-green-600 py-4 px-6 rounded-lg">
                    {bottomBlock && topBlock && formValid && <>Stack blocks!</>}
                    {(!bottomBlock || !topBlock) && <>Select two blocks to get started</>}
                    {bottomBlock && topBlock && !formValid && <>Please choose two different blocks to stack</>}
                  </button>
                </div>
              </div>
              }
            </div>

          </div>
        }
        </div>
    )
}

export default Stackinator
