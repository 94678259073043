import React from 'react'
import ReactImageAppear from 'react-image-appear'
import { XIcon, XCircleIcon } from '@heroicons/react/outline'
import loading from '../assets/loading.gif'

function SelectedBlockList({ blockList, deselectBlock, deselectAll, title, blockSubtitle }) {
    return (
      <>
        <dl className="w-full space-y-6 divide-y divide-gray-200">
          <div className="">
              <>
                <dt className="text-lg">
                  <div className="text-left bg-gray-200 w-full p-3 border-2 border-gray-200 rounded-lg flex justify-between items-center text-gray-400 focus:outline-none">
                    <span className="font-bold text-gray-900">{title}</span>
                    { blockList.length > 0 && (
                      <span className="flex flex-row items-center gap-2 text-gray-400 cursor-pointer" onClick={() => deselectAll()}>
                        <span>
                          Deselect All
                        </span>
                        <XCircleIcon className="h-8 w-8"/>
                      </span>
                    )}
                  </div>
                </dt>
                <dd className="pt-2 pb-6 px-6 border-l-2 border-r-2 border-b-2 border-gray-200 rounded-b-xl">
                  <ul role="list" className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
                    { blockList.map((blockId) => (
                      <li key={blockId.toString()} className="flex flex-col gap-4 w-full justify-center group">
                        <div className="relative z-10 invisible group-hover:visible">
                          <button className="absolute top-2 right-2 w-10 h-10 rounded-full bg-red-700 text-white text-4xl flex items-center justify-center" onClick={() => deselectBlock(blockId)}>
                            <span>
                              <XIcon className="text-white h-6 w-6"/>
                            </span>
                          </button>
                        </div>
                        <ReactImageAppear
                            key={'selected-' + blockId.toString()}
                            src={"https://storageapi.fleek.co/monster-blocks-bucket/production/".concat(blockId.toString())}
                            className="bg-white"
                            loader={loading}
                            loaderStyle={{ backgroundColor: "white", position: "absolute", top: 100 }}
                            placeholderStyle={{ backgroundColor: "white" }}
                        />
                        <div className="flex flex-row justify-between items-center gap-2">
                          <div className="flex flex-col gap-2">
                            <p className="block text-xs font-medium text-gray-900 truncate pointer-events-none">Monster Block #{blockId}</p>
                            <p className="block text-xs font-medium text-gray-500 pointer-events-none">{blockSubtitle}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>

                  { blockList.length === 0 && (
                    <p className="text-left text-md font-medium text-gray-900 pt-2">
                      No blocks selected yet.
                    </p>
                  )}
                </dd>
              </>
          </div>
        </dl>
      </>
    )
}

export default SelectedBlockList
