import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import MonsterBlockAbi from '../abi/monsterBlocks.json'
import contractConfig from '../contracts.config.js';

const MONSTER_BLOCK_CONTRACT_ADDRESS = contractConfig['MonsterBlocks']

const MonsterBlockInterface = new utils.Interface(MonsterBlockAbi)

function monsterBlockContract() {
  return new Contract(MONSTER_BLOCK_CONTRACT_ADDRESS, MonsterBlockInterface)
}

export { MonsterBlockInterface, monsterBlockContract, MONSTER_BLOCK_CONTRACT_ADDRESS }
