import React from 'react'
import twitter from '../assets/twitter.png'
import discord from '../assets/discord.png'
import openSea from '../assets/opensea.svg'
import looksRare from '../assets/looksrare.svg'
import etherscan from '../assets/etherscan.svg'

import { MONSTER_BLOCK_CONTRACT_ADDRESS } from '../model/monsterBlockContract'

function Footer () {
    return (
        <footer className="flex justify-center space-x-4 p-6 absolute bottom-0 w-full">
          <a href="https://twitter.com/monster_blocks" target="_blank" rel="noreferrer">
            <img src={twitter} alt="Twitter" className="h-16 object-contain" />
          </a>
          <a href="https://discord.gg/bYU7ApuCnm" target="_blank" rel="noreferrer">
            <img src={discord} alt="Discord" className="h-16 p-3 object-contain" />
          </a>
          <a href="https://opensea.io/collection/monster-blocks" target="_blank" rel="noreferrer">
            <img src={openSea} alt="OpenSea" className="h-16 p-1 object-contain" />
          </a>
          <a href="https://looksrare.org/collections/0xa56a4f2b9807311AC401c6afBa695D3B0C31079d" target="_blank" rel="noreferrer">
            <img src={looksRare} alt="LooksRare" className="h-16 p-2 object-contain" />
          </a>
          <a href={"https://etherscan.io/address/".concat(MONSTER_BLOCK_CONTRACT_ADDRESS)} target="_blank" rel="noreferrer">
            <img src={etherscan} alt="Etherscan" className="h-16 p-3 object-contain" />
          </a>
        </footer>
    )
}

export default Footer
