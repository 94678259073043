import React, { useState, useEffect } from 'react'
import axios from 'axios'
// Assets
import homeTopAnimation from '../assets/home-top-animation.gif'
import homeRightPanelTwo from '../assets/home-right-panel-two.png'
import homeLeftPanel from '../assets/home-left-panel.png'
import homeTechPanel from '../assets/home-tech-panel.png'
import homeStackinatorPanel from '../assets/home-stackinator-panel.gif'
import teamEllipse from '../assets/team-ellipse.png'
import chrisHeadshot from '../assets/chris-headshot.png'
import zackHeadshot from '../assets/zack-headshot.png'
import collinHeadshot from '../assets/collin-headshot.png'
import mikeHeadshot from '../assets/mike-headshot.png'
import twitter from '../assets/twitter.png'
import bgEllipse from '../assets/bg-ellipse.png'
import multivac from '../assets/multivac.png'

function Home() {
  const[blocksBurned, setBlocksBurned] = useState(null)
  const[towersErected, setTowersErected] = useState(null)
  const statsUrl = 'https://api.monsterblocks.io/stats'

  useEffect(() => {
    axios.get(statsUrl).then(response => {
      setBlocksBurned(response.data['blocks_burned'])
      setTowersErected(response.data['towers_erected'])
    });
  }, []);

      return (
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 lg:gap-24 mb-8 px-4 md:px-8 lg:px-16 xl:px-32">
            <div className="flex flex-col items-start self-center space-y-6">
              <h1 className="font-cursive uppercase text-6xl">
                  Monster Blocks
              </h1>
              <div className="">
                Monster Blocks are gloriously illustrated towers, constructed from
                monstrous building blocks, erected right here on the blockchain.
                Using our proprietary stacking technology, the Stackinator, Monster
                Blocks can be merged and stacked to create new, magnificent towers
                for all the metaverse to admire and envy. A maximum of 10,000
                Monster Blocks will ever exist.
              </div>

               <div className="grid grid-cols-3 gap-4">
                 <a href="https://discord.gg/bYU7ApuCnm" target="_blank" rel="noreferrer"
                    className="w-full text-center text-lg xl:text-xl bg-green-500 text-white font-cursive hover:text-gray-100 hover:bg-green-600 py-4 px-4 rounded-lg">
                 Join The Discord
                 </a>

                 <a href="https://opensea.io/collection/monster-blocks" target="_blank" rel="noreferrer"
                    className="w-full text-center text-lg xl:text-xl bg-blue-400 text-white font-cursive hover:text-gray-100 hover:bg-blue-500 py-4 px-4 rounded-lg">
                 Buy on OpenSea
                 </a>

                 <a href="https://nftx.io/vault/0xc016e4b42f80050bbf1d6244c6adbd0b10fe93c5/info/" target="_blank" rel="noreferrer"
                    className="w-full text-center text-lg xl:text-xl bg-red-400 text-white font-cursive hover:text-gray-100 hover:bg-red-500 py-4 px-4 rounded-lg">
                 Stake on NFTX
                 </a>


               </div>
            </div>
            <div className="flex justify-center">
              <img src={homeTopAnimation} alt="Home Right Panel" className="h-128 object-contain" />
            </div>
          </div>

          <div>
            <div className="relative mt-16 md:mt-48 grid grid-cols-1 md:grid-cols-6 gap-2 md:gap-16 lg:gap-24 mb-8 px-4 z-20">
              <div className="md:col-span-3 lg:col-span-2 md:col-start-1 lg:col-start-2 flex flex-col shadow-md rounded-lg bg-white">
                <div className="flex flex-col bg-yellow-300 rounded-t-lg px-2 py-6">
                  <span className="text-center font-cursive uppercase text-3xl">
                    Blocks Burned
                  </span>
                </div>
                <div className="items-center text-center py-4 font-cursive uppercase text-6xl">
                  {blocksBurned}
                </div>
              </div>
              <div className="md:col-span-3 lg:col-span-2 flex flex-col shadow-md rounded-lg bg-white">
                <div className="flex flex-col bg-yellow-300 rounded-t-lg px-2 py-6">
                  <span className="text-center font-cursive uppercase text-3xl">
                    Towers Erected
                  </span>
                </div>
                <div className="items-center text-center py-4 font-cursive uppercase text-6xl">
                  {towersErected}
                </div>
              </div>
            </div>
            <div className="relative hidden md:flex md:justify-center -mt-112 z-10">
              <img src={multivac} alt="Multivac" className="h-128 object-contain" />
            </div>
            <div className="relative hidden md:flex md:justify-center -mt-112 md:-ml-72 lg:-ml-96 xl:-ml-112 z-0">
              <img src={bgEllipse} alt="Ellipse" className="h-112 object-contain" />
            </div>
          </div>

          <div className="mt-16 md:mt-2 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 lg:gap-24 mb-8 px-4 md:px-8 lg:px-16 xl:px-32">
            <div className="flex flex-col self-center space-y-6">
              <h1 className="font-cursive text-6xl uppercase">
                  Monster Block Traits
              </h1>
              <div className="">
                Each Monster Block is built out of smaller block “properties,” which,
                as you have certainly come to expect by now, vary in shape and color
                and rarity and intrigue.
              </div>
              <div className="">
                Each property is hand drawn, hand colored, and hand fed into a
                magnificently complex algorithm developed by an Oxford mathematician
                to ensure that each Monster Block is stackable, one of a kind, and
                worth its weight in ETH.
              </div>
            </div>
            <div className="flex justify-center">
              <img src={homeRightPanelTwo} alt="Home Right Panel" className="h-128 object-contain" />
            </div>
          </div>

          <div id="tech" className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 lg:gap-24 mb-8 px-4 md:px-8 lg:px-16 xl:px-32">
            <div className="flex justify-center">
              <img src={homeTechPanel} alt="Home Tech Panel" className="h-128 object-contain" />
            </div>
            <div className="flex flex-col self-center space-y-6">
              <h1 className="font-cursive text-6xl uppercase">
                The Tech
              </h1>
              <div className="">
              Our smart contract is no copy-and-paste job; it is artisanally crafted.
              We have integrated with
              {' '}
              <a href="https://chain.link/solutions/chainlink-vrf" target="_blank" rel="noreferrer"
                 className="underline text-green-600 hover:text-green-700">
                Chainlink VRF
              </a>
              {' '}
              to ensure that each selected trait
              is verifiably random, and is generated in real-time as you mint. You
              don't know what you are going to mint, and we don't either!
              </div>
              <div className="">
              Not only that, but all trait metadata is stored and generated on-chain;
              you can look it up whenever you want and it will last as long as Ethereum does.
              And we embrace decentralization by utilizing IPFS for all Monster Block images.
              Even this website is hosted on IPFS!
              </div>
            </div>
          </div>

          <div id="stacking" className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 lg:gap-24 mb-16 px-4 md:px-8 lg:px-16 xl:px-32">
            <div className="flex flex-col self-center space-y-6">
              <h1 className="font-cursive text-6xl uppercase">
                Monster Stacking
              </h1>
              <div className="">
                We all love a good tower. That’s why we’ve developed the Stackinator,
                an ingenious mechanism that allows users to stack their Monster Blocks
                atop one another to create ridiculously tall, ridiculously rare, and
                ridiculously monstrous NFT towers.
              </div>
              <div className="">
                To use the Stackinator, we will burn the two originals and issue you
                a new combined tower. This will reduce the total number of Monster
                Blocks in circulation by one.
              </div>
            </div>
            <div className="flex justify-center">
              <img src={homeStackinatorPanel} alt="Home Stackinator Panel" className="h-128 object-contain" />
            </div>
          </div>

          <div id="team" className="flex justify-center my-24 px-4">
            <img src={teamEllipse} alt="Ellipse" className="absolute -mt-8 h-256 object-contain z-0" />
            <div className="flex flex-col z-10">
              <h1 className="my-8 text-center font-cursive uppercase text-6xl">
                Our Team
              </h1>

              <div className="grid grid-cols-1 md:grid-cols-4 gap-2 sm:gap-12 px-2 lg:px-36 xl:px-72">

                <div className="col-span-1">
                  <img src={chrisHeadshot} alt="Chris" />
                </div>
                <div className="mt-2 sm:-mt-2 col-span-1 md:col-span-3 flex flex-col self-center space-y-4 text-gray-800">
                  <h3 className="flex flex-row space-x-8 font-cursive uppercase text-3xl">
                    <span>
                      Chris
                    </span>
                    <a href="https://twitter.com/chrishol">
                      <img src={twitter} alt="Twitter" className="h-8 object-contain" />
                    </a>
                  </h3>
                  <span>
                    Our Lead Developer Chris has worked in software for 15+ years; both as a VC-backed founder and for Silicon Valley darlings like Square and DoorDash. His favorite monsters are those that live in the depths of the ocean.
                  </span>
                </div>

                <div className="mt-2 sm:mt-0 col-span-1">
                  <img src={zackHeadshot} alt="Zack" />
                </div>
                <div className="mt-2 sm:-mt-2 col-span-1 md:col-span-3 flex flex-col self-center space-y-4 text-gray-800">
                  <h3 className="flex flex-row space-x-8 font-cursive uppercase text-3xl">
                    <span>
                      Zack
                    </span>
                    <a href="https://twitter.com/zlondonz">
                      <img src={twitter} alt="Twitter" className="h-8 object-contain" />
                    </a>
                  </h3>
                  <span>
                    Our Artist Extraordinaire Zack (aka Gossip Goblin) has spent the last decade working as a product designer on new and emergent technologie ranging from robot baristas to AI nurses to self-heating coffee mugs to VR experiences. His favorite monsters are the ones that live inside your DMs.
                  </span>
                </div>

                <div className="mt-2 sm:mt-0 col-span-1">
                  <img src={collinHeadshot} alt="Collin" />
                </div>
                <div className="mt-2 sm:-mt-2 col-span-1 md:col-span-3 flex flex-col self-center space-y-4 text-gray-800">
                  <h3 className="flex flex-row space-x-8 font-cursive uppercase text-3xl">
                    <span>
                      Collin
                    </span>
                    <a href="https://twitter.com/SeeEsTee2006">
                      <img src={twitter} alt="Twitter" className="h-8 object-contain" />
                    </a>
                  </h3>
                  <span>
                    Our Community Manager Collin (aka cst2006) is a Fiber Network Technician and avid sports fan who started in the NFT community as an early adopter of NBA Topshot, and has since developed valuable relationships all across the NFT space. His favorite monsters are Sully and Mike Wazowski.
                  </span>
                </div>

                <div className="mt-2 sm:mt-0 col-span-1">
                  <img src={mikeHeadshot} alt="Mike" />
                </div>
                <div className="mt-2 sm:-mt-2 col-span-1 md:col-span-3 flex flex-col self-center space-y-4 text-gray-800">
                  <h3 className="flex flex-row space-x-8 font-cursive uppercase text-3xl">
                    <span>
                      Mike
                    </span>
                    <a href="https://twitter.com/Mike_Lazar">
                      <img src={twitter} alt="Twitter" className="h-8 object-contain" />
                    </a>
                  </h3>
                  <span>
                    Mike has spent the past 15 years in government, VC, and business, as an investor and consultant. He is currently obsessed with his own little monster that he welcomed in February 2021.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
}

export default Home
