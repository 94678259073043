// Todo list:
// Etherscan links

import React, { useState, useEffect } from 'react'
import { useEthers, useContractFunction } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'

import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

import SelectedBlockList from '../Components/SelectedBlockList';
import SelectableBlock from '../Components/SelectableBlock';
import TransactionDialog from '../Components/TransactionStatusDialog';
import ErrorComponent from '../Components/ErrorComponent';

import useMonsterMarket from '../hooks/useMonsterMarket';
import useOwnedTokens from '../hooks/useOwnedTokens';
import useTokenBalance from '../hooks/useTokenBalance';

import { monsterBlockContract, MONSTER_BLOCK_CONTRACT_ADDRESS, MonsterBlockInterface } from '../model/monsterBlockContract'
import { monsterMarketContract, MONSTER_MARKET_CONTRACT_ADDRESS, MonsterMarketInterface } from '../model/monsterMarketContract'
import { estimateGas } from '../calls/estimateGas'

import etherscan from '../assets/etherscan.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function MonsterMarket() {
  const { account, library, activateBrowserWallet } = useEthers()
  const {
    isLoaded,
    userMarketBalance,
    poolBalance,
    poolTokens,
    marketApproved
  } = useMonsterMarket(account)

  const ownedBlocks = useOwnedTokens(account)
  const blockBalance = useTokenBalance(account)

  const [selectedBlocksToRedeem, setSelectedBlocksToRedeem] = useState([])
  const [selectedBlocksToDeposit, setSelectedBlocksToDeposit] = useState([])

  const [showApprovalTxModal, setShowApprovalTxModal] = useState(false)
  const [showMarketTxModal, setShowMarketTxModal] = useState(false)
  const [transactionStatus, setTransactionStatus] = useState('empty')
  const [lastTransaction, setLastTransaction] = useState(null)
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const BLOCK_PAGE_SIZE = 20
  const [currentOwnedTokensShown, setCurrentOwnedTokensShown] = useState(BLOCK_PAGE_SIZE)
  const showMoreOwnedTokens = () => {
    setCurrentOwnedTokensShown(currentOwnedTokensShown + BLOCK_PAGE_SIZE)
  }
  const [currentPoolTokensShown, setCurrentPoolTokensShown] = useState(BLOCK_PAGE_SIZE)
  const showMorePoolTokens = () => {
    setCurrentPoolTokensShown(currentPoolTokensShown + BLOCK_PAGE_SIZE)
  }

  const selectBlockToRedeem = (block) => {
    const exists = selectedBlocksToRedeem.includes(block.id)
    if (!exists) {
      let newArray = selectedBlocksToRedeem.slice()
      newArray.push(block.id)
      setSelectedBlocksToRedeem(newArray)
    }
  }

  const selectBlockToDeposit = (block) => {
    const exists = selectedBlocksToDeposit.includes(block.id)
    if (!exists) {
      let newArray = selectedBlocksToDeposit.slice()
      newArray.push(block.id)
      setSelectedBlocksToDeposit(newArray)
    }
  }

  const deselectBlockToDeposit = (blockIdToRemove) => {
    let newArray = []
    selectedBlocksToDeposit.forEach((blockId) => {
      if (blockId !== blockIdToRemove) {
        newArray.push(blockId)
      }
    })
    setSelectedBlocksToDeposit(newArray)
  }

  const deselectBlockToRedeem = (blockIdToRemove) => {
    let newArray = []
    selectedBlocksToRedeem.forEach((blockId) => {
      if (blockId !== blockIdToRemove) {
        newArray.push(blockId)
      }
    })
    setSelectedBlocksToRedeem(newArray)
  }

  const deselectAllBlocksToDeposit = () => {
    let newArray = []
    setSelectedBlocksToDeposit(newArray)
  }

  const deselectAllBlocksToRedeem = () => {
    let newArray = []
    setSelectedBlocksToRedeem(newArray)
  }

  const { send: approvalSend, state: approvalState } = useContractFunction(monsterBlockContract(), 'setApprovalForAll');
  const approveMonsterMarket = async () => {
    try {
      setHasError(false)
      const gasEstimation = await estimateGas(
        new Contract(MONSTER_BLOCK_CONTRACT_ADDRESS, MonsterBlockInterface, library),
        'setApprovalForAll',
        [MONSTER_MARKET_CONTRACT_ADDRESS, 1, { from: account }],
        3000,
      );

      approvalSend(MONSTER_MARKET_CONTRACT_ADDRESS, 1, { gasLimit: gasEstimation });
    } catch (e) {
      console.error(e)
      setHasError(true)
      setErrorMessage(e.message)
    }
  };

  const { send: depositOne, state: depositOneState } = useContractFunction(monsterMarketContract(), 'depositOne');
  const { send: depositMany, state: depositManyState } = useContractFunction(monsterMarketContract(), 'depositMany');
  const { send: redeemOne, state: redeemOneState } = useContractFunction(monsterMarketContract(), 'redeemOne');
  const { send: redeemMany, state: redeemManyState } = useContractFunction(monsterMarketContract(), 'redeemMany');
  const { send: depositAndRedeemOne, state: depositAndRedeemOneState } = useContractFunction(monsterMarketContract(), 'depositAndRedeemOne');
  const { send: depositAndRedeemMany, state: depositAndRedeemManyState } = useContractFunction(monsterMarketContract(), 'depositAndRedeemMany');

  const confirmTransaction = async () => {
    try {
      setHasError(false)

      let functionToCall
      let functionName
      let args

      if (selectedBlocksToDeposit.length === 1 && selectedBlocksToRedeem.length === 1) {
        functionToCall = depositAndRedeemOne
        functionName = 'depositAndRedeemOne'
        args = [
          selectedBlocksToDeposit[0],
          selectedBlocksToRedeem[0]
        ]
      } else if (selectedBlocksToDeposit.length > 0 && selectedBlocksToRedeem.length > 0) {
        functionToCall = depositAndRedeemMany
        functionName = 'depositAndRedeemMany'
        args = [
          selectedBlocksToDeposit,
          selectedBlocksToRedeem
        ]
      } else if (selectedBlocksToDeposit.length === 1) {
        functionToCall = depositOne
        functionName = 'depositOne'
        args = [
          selectedBlocksToDeposit[0]
        ]
      } else if (selectedBlocksToRedeem.length === 1) {
        functionToCall = redeemOne
        functionName = 'redeemOne'
        args = [
          selectedBlocksToRedeem[0]
        ]
      } else if (selectedBlocksToDeposit.length > 1) {
        functionToCall = depositMany
        functionName = 'depositMany'
        args = [
          selectedBlocksToDeposit
        ]
      } else if (selectedBlocksToRedeem.length > 1) {
        functionToCall = redeemMany
        functionName = 'redeemMany'
        args = [
          selectedBlocksToRedeem
        ]
      } else {
        return; // Nothing selected
      }

      const gasEstimation = await estimateGas(
        new Contract(MONSTER_MARKET_CONTRACT_ADDRESS, MonsterMarketInterface, library),
        functionName,
        [...args, { from: account }],
        3000,
      );

      functionToCall(...args, { gasLimit: gasEstimation });
    } catch (e) {
      console.error(e)
      setHasError(true)
      setErrorMessage(e.message)
    }
  };

  const callbackTxState = (currentTxState) => {
    if (currentTxState && currentTxState.status === 'Mining') {
      setShowMarketTxModal(true);
      setTransactionStatus('mining');
      setLastTransaction(currentTxState.transaction.hash);
    } else if (currentTxState && currentTxState.status === 'Success' && currentTxState.transaction.hash === lastTransaction) {
      setTransactionStatus('success');
      setLastTransaction(null);
    } else if (currentTxState && (currentTxState.status === 'Fail' || currentTxState.status === 'Exception')) {
      setTransactionStatus('failed');
      setLastTransaction(null);
    }
  }

  useEffect(() => {
    [
      approvalState,
      depositOneState,
      depositManyState,
      redeemOneState,
      redeemManyState,
      depositAndRedeemOneState,
      depositAndRedeemManyState
    ].forEach(callbackTxState)
  }, [
    approvalState,
    depositOneState,
    depositManyState,
    redeemOneState,
    redeemManyState,
    depositAndRedeemOneState,
    depositAndRedeemManyState
  ]);

  return (
    <div className="container mx-auto">

      <div className="my-16 px-4 md:px-8 lg:px-16 xl:px-32">
        <div className="flex flex-col items-start self-center space-y-2">
          <h1 className="font-cursive uppercase text-6xl">
            Monster Market
          </h1>

          <a href={"https://etherscan.io/address/".concat(MONSTER_MARKET_CONTRACT_ADDRESS)} target="_blank" rel="noreferrer"
             className="flex flex-row gap-0 items-center">
            <img src={etherscan} alt="Etherscan" className="h-12 p-3 object-contain" />
            <span className="text-xs text-gray-600">
              Monster Market Smart Contract
            </span>
          </a>

          { !account && (
            <div className="pb-6">
              <button onClick={activateBrowserWallet}
                      className="mt-4 text-3xl bg-green-500 text-white font-cursive uppercase hover:text-gray-100 hover:bg-green-600 py-4 px-24 rounded-lg">
               Connect Wallet
              </button>
            </div>
          )}

          { account && !isLoaded && (
            <>
              <div className="flex flex-row justify-center w-full py-32">
                  <div className="loader ease-linear rounded-full border-8 border-t-8 border-green-500 h-12 w-12 text-center"></div>
              </div>
            </>
          )}

          { account && isLoaded && (
            <>

              <div>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">

                  <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">Your Monster Block Balance</dt>
                    <dd className="mt-1 text-6xl font-cursive font-regular text-gray-900">
                      { blockBalance }
                      <span className="pl-4 text-2xl uppercase">
                       Blocks
                      </span>
                    </dd>
                  </div>

                  <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">Your Monster Market Balance</dt>
                    <dd className="mt-1 text-6xl font-cursive font-regular text-gray-900">
                      { userMarketBalance }
                      <span className="pl-4 text-2xl uppercase">
                       Blocks
                      </span>
                    </dd>
                  </div>

                  <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">Blocks in the Monster Market Pool</dt>
                    <dd className="mt-1 text-6xl font-cursive font-regular text-gray-900">
                      { poolBalance }
                      <span className="pl-4 text-2xl uppercase">
                       Blocks
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>

              <div className="py-6 w-full">
                { (selectedBlocksToDeposit.length > 0 || selectedBlocksToRedeem.length > 0) && (
                  <>
                    <div className="text-lg">
                      <div className="text-left bg-green-200 w-full p-3 rounded-lg flex justify-between items-start text-gray-400 focus:outline-none">
                        <span className="font-bold text-gray-900">
                          Your Monster Market Transaction
                        </span>
                      </div>
                    </div>
                    <div className="py-4 px-6 border-l-2 border-r-2 border-b-2 border-gray-200 rounded-b-xl flex flex-row gap-4 justify-between items-center">
                      <div>
                        { (selectedBlocksToDeposit.length > 10 || selectedBlocksToRedeem.length > 10) && (
                          <>
                            The maximum is 10 per transaction.
                          </>
                        )}

                        { selectedBlocksToDeposit.length > 0 && selectedBlocksToRedeem.length > 0 && (
                          <p>
                            { selectedBlocksToDeposit.length <= 10 && selectedBlocksToDeposit.length === selectedBlocksToRedeem.length && (
                              <>
                                You are swapping {selectedBlocksToDeposit.length} block{ selectedBlocksToDeposit.length > 1 && 's'}.

                                {' '}

                                { userMarketBalance > 0 && (
                                  <>
                                    This will not require any of your Monster Market balance.
                                  </>
                                )}
                              </>
                            )}

                            { selectedBlocksToDeposit.length !== selectedBlocksToRedeem.length && (
                              <>
                                If swapping, please choose the same number of blocks to deposit and redeem.
                              </>
                            )}
                          </p>
                        )}
                        { selectedBlocksToDeposit.length > 0 && selectedBlocksToDeposit.length <= 10 && selectedBlocksToRedeem.length === 0 && (
                          <p>
                            You are depositing {selectedBlocksToDeposit.length} block{ selectedBlocksToDeposit.length > 1 && 's'}.
                            You will receive a Monster Market balance that you can use
                            to redeem {selectedBlocksToDeposit.length} block{ selectedBlocksToDeposit.length > 1 && 's'} at any later time.
                          </p>
                        )}
                        { selectedBlocksToDeposit.length === 0 && selectedBlocksToRedeem.length > 0 && selectedBlocksToRedeem.length <= 10 && (
                          <p>
                            You are redeeming {selectedBlocksToRedeem.length} block{ selectedBlocksToRedeem.length > 1 && 's'}.

                            {' '}

                            { userMarketBalance >= selectedBlocksToRedeem.length && (
                              <>
                              This will use up {selectedBlocksToRedeem.length} block{ selectedBlocksToRedeem.length > 1 && 's'} from
                              your Monster Market balance.
                              </>
                            )}

                            { userMarketBalance < selectedBlocksToRedeem.length && (
                              <>
                                You don't currently have enough Monster Market balance
                                for this action; please redeem less blocks or add
                                blocks to deposit.
                              </>
                            )}
                          </p>
                        )}
                      </div>
                      <div>
                        { (marketApproved || selectedBlocksToDeposit.length === 0) &&
                          (selectedBlocksToDeposit.length === 0 || selectedBlocksToRedeem.length === 0 ||
                           (selectedBlocksToDeposit.length === selectedBlocksToRedeem.length && selectedBlocksToDeposit.length <= 10)) && (
                          <>
                            { (userMarketBalance + selectedBlocksToDeposit.length) >= selectedBlocksToRedeem.length && (
                              <button onClick={() => confirmTransaction()}
                                      className="text-xl bg-green-500 text-white font-cursive uppercase hover:text-gray-100 hover:bg-green-600 py-4 px-4 rounded-lg focus:outline-none">
                                Confirm Transaction
                              </button>
                            )}
                          </>
                        )}
                        { !marketApproved && selectedBlocksToDeposit.length !== 0 && (
                          <button onClick={() => approveMonsterMarket()}
                                  className="text-xl bg-pink-500 text-white font-cursive uppercase hover:text-gray-100 hover:bg-pink-600 py-4 px-4 rounded-lg focus:outline-none">
                            Approve Monster Market
                          </button>
                        )}
                      </div>
                    </div>

                    <ErrorComponent showError={hasError} message={errorMessage} />
                  </>
                )}
              </div>

              <div className="grid grid-cols-2 gap-4">
                { selectedBlocksToDeposit && (
                  <>
                    <SelectedBlockList blockList={selectedBlocksToDeposit} deselectBlock={deselectBlockToDeposit} deselectAll={deselectAllBlocksToDeposit} blockSubtitle='Owned by You' title='Blocks to Deposit' />
                  </>
                )}

                { selectedBlocksToRedeem && (
                  <>
                    <SelectedBlockList blockList={selectedBlocksToRedeem} deselectBlock={deselectBlockToRedeem} deselectAll={deselectAllBlocksToRedeem} blockSubtitle='In Monster Market Pool' title='Blocks to Redeem' />
                  </>
                )}
              </div>

              <dl className="w-full mt-6 space-y-6 divide-y divide-gray-200">
                <Disclosure as="div" className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full p-3 bg-gray-200 border-2 border-gray-200 rounded-lg flex justify-between items-start text-gray-400 focus:outline-none">
                          <span className="font-bold text-gray-900">Your Blocks ({blockBalance} owned)</span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="pt-2 pb-6 px-12 border-l-2 border-r-2 border-b-2 border-gray-200 rounded-b-xl">
                        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                          { ownedBlocks.filter((token, index) => (currentOwnedTokensShown > index)).map((token) => (
                            <SelectableBlock block={token} selectBlock={selectBlockToDeposit} blockSubtitle='Owned by You' />
                          ))}

                          { currentOwnedTokensShown < ownedBlocks.length && (
                            <div className="col-span-4 flex flex-row justify-center">
                              <button onClick={showMoreOwnedTokens}
                                      className="px-16 text-2xl uppercase font-cursive bg-green-500 text-white hover:text-gray-100 hover:bg-green-600 focus:outline-none py-2 px-4 rounded-xl">
                                Show More
                              </button>
                            </div>
                          )}
                        </ul>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </dl>

              <dl className="w-full mt-6 space-y-6 divide-y divide-gray-200">
                <Disclosure as="div" className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full p-3 bg-gray-200 border-2 border-gray-200 rounded-lg flex justify-between items-start text-gray-400 focus:outline-none">
                          <span className="font-bold text-gray-900">Monster Market Pool ({poolBalance} in pool)</span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="pt-2 pb-6 px-12 border-l-2 border-r-2 border-b-2 border-gray-200 rounded-b-xl">
                        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                          { poolTokens.filter((token, index) => (currentPoolTokensShown > index)).map((token) => (
                            <SelectableBlock block={token} selectBlock={selectBlockToRedeem} blockSubtitle='In Monster Market Pool' />
                          ))}

                          { currentPoolTokensShown < poolTokens.length && (
                            <div className="col-span-4 flex flex-row justify-center">
                              <button onClick={showMorePoolTokens}
                                      className="w-full text-2xl uppercase font-cursive bg-green-500 text-white hover:text-gray-100 hover:bg-green-600 focus:outline-none py-2 px-4 rounded-xl">
                                Show More
                              </button>
                            </div>
                          )}
                        </ul>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </dl>
            </>
          )}

        </div>
      </div>

      {/* Approval Transaction Modal */}
      <TransactionDialog
        subtitle="Monster Market Approval"
        isShowing={showApprovalTxModal}
        setShowTxModal={setShowApprovalTxModal}
        transactionStatus={transactionStatus}

        miningStatus={
          <>
            <div className="w-full bg-yellow-300 rounded-t-xl px-16 py-6">
              <h3 className="font-cursive uppercase text-4xl">
                Processing...
              </h3>
            </div>
            <div className="text-md whitespace-pre-wrap py-8">
              <p className="font-work font-regular text-md whitespace-pre-wrap">
                Please wait while your transaction confirms.
              </p>
            </div>
          </>
        }

        successStatus={
          <>
            <div className="w-full bg-yellow-300 rounded-t-xl px-16 py-6">
              <h3 className="font-cursive uppercase text-4xl">
                Confirmed!
              </h3>
            </div>
            <div className="text-md whitespace-pre-wrap py-8">
              <p className="font-work font-regular text-md whitespace-pre-wrap">
                Awesome! The Monster Market was approved to deposit your blocks.
              </p>
            </div>
          </>
        }

        rejectedStatus={
          <>
            <div className="w-full bg-yellow-300 rounded-t-xl px-16 py-6">
              <h3 className="font-cursive uppercase text-4xl">
                Transaction Rejected
              </h3>
            </div>
            <div className="text-md whitespace-pre-wrap py-8">
              <p className="font-work font-regular text-md whitespace-pre-wrap">
                Please try again.
              </p>
            </div>
          </>
        }
      />

      {/* Market Transaction Modal */}
      <TransactionDialog
        subtitle="Monster Market"
        isShowing={showMarketTxModal}
        setShowTxModal={setShowMarketTxModal}
        transactionStatus={transactionStatus}

        miningStatus={
          <>
            <div className="w-full bg-yellow-300 rounded-t-xl px-16 py-6">
              <h3 className="font-cursive uppercase text-4xl">
                Processing...
              </h3>
            </div>
            <div className="text-md whitespace-pre-wrap py-8">
              <p className="font-work font-regular text-md whitespace-pre-wrap">
                Please wait while your transaction confirms.
              </p>
            </div>
          </>
        }

        successStatus={
          <>
            <div className="w-full bg-yellow-300 rounded-t-xl px-16 py-6">
              <h3 className="font-cursive uppercase text-4xl">
                Confirmed!
              </h3>
            </div>
            <div className="w-full px-8 flex flex-col gap-8 text-md whitespace-pre-wrap py-8">
              <p className="font-work font-regular text-md whitespace-pre-wrap">
                Your transaction is complete!
              </p>
              <div class="flex flex-row justify-between gap-4">
                <a href={"https://opensea.io/".concat(account).concat('?search[sortBy]=LAST_TRANSFER_DATE&search[sortAscending]=false')} target="_blank" rel="noreferrer"
                   className="flex items-center justify-center w-full text-center text-md bg-blue-400 text-white font-cursive hover:text-gray-100 hover:bg-blue-500 py-4 px-4 rounded-lg">
                  <span>
                  View on OpenSea
                  </span>
                </a>
                <button onClick={() => window.location.reload()}
                   className="flex items-center justify-center w-full text-center text-md bg-blue-400 text-white font-cursive hover:text-gray-100 hover:bg-blue-500 py-4 px-4 rounded-lg">
                  <span>
                  Reload Market
                  </span>
                </button>
              </div>
            </div>
          </>
        }

        rejectedStatus={
          <>
            <div className="w-full bg-yellow-300 rounded-t-xl px-16 py-6">
              <h3 className="font-cursive uppercase text-4xl">
                Transaction Rejected
              </h3>
            </div>
            <div className="text-md whitespace-pre-wrap py-8">
              <p className="font-work font-regular text-md whitespace-pre-wrap">
                Please try again.
              </p>
            </div>
          </>
        }
      />
    </div>
  )
}

export default MonsterMarket
