import React from 'react'
import Navigation from './Navigation'
import { Link } from 'react-router-dom'
import navIcon from '../assets/nav-icon.png';

function Header() {
    return (
        <header className="border-b p-3 flex justify-between items-center">
            <Link to="/" className="flex flex-row items-center text-3xl gap-x-4">
              <img src={navIcon} alt="Logo" className="h-16 object-contain" />
              <span className="font-cursive uppercase">
                Monster Blocks
              </span>
            </Link>

            <Navigation />
        </header>
    )
}

export default Header
