import { useContractCall } from '@usedapp/core'
import { MonsterBlockInterface, MONSTER_BLOCK_CONTRACT_ADDRESS } from '../model/monsterBlockContract'

export default function useTokenBalance(address) {
  const [tokenBalance] = useContractCall({
    abi: MonsterBlockInterface,
    address: MONSTER_BLOCK_CONTRACT_ADDRESS,
    method: 'balanceOf',
    args: address && [address]
  }) ?? [-1]
  return parseInt(tokenBalance)
}
