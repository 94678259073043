import { useContractCall } from '@usedapp/core'
import { MonsterBricksInterface, MONSTER_BRICKS_CONTRACT_ADDRESS } from '../model/monsterBricksContract'

export default function useLatestTokenClaimed(address) {
  const [latestToken] = useContractCall({
    abi: MonsterBricksInterface,
    address: MONSTER_BRICKS_CONTRACT_ADDRESS,
    method: 'lastTokenClaimed',
    args: address && [address]
  }) ?? [-1]
  return parseInt(latestToken)
}
