import { useContractCalls } from '@usedapp/core'
import { MonsterBlockInterface, MONSTER_BLOCK_CONTRACT_ADDRESS } from '../model/monsterBlockContract'
import useTokenBalance from '../hooks/useTokenBalance'

export default function useOwnedTokens(address) {
  const tokenBalance = useTokenBalance(address)

  let tokenIdCalls = []
  for (let i = 0; tokenBalance && i < tokenBalance; i++) {
    tokenIdCalls.push({
      abi: MonsterBlockInterface,
      address: MONSTER_BLOCK_CONTRACT_ADDRESS,
      method: 'tokenOfOwnerByIndex',
      args: [address, i],
    })
  }
  const result = useContractCalls(tokenIdCalls) ?? []
  return result.filter((token) => {
    return !!token
  }).sort((firstToken, secondToken) => {
    return parseInt(firstToken[0]) - parseInt(secondToken[0])
  }).map((token) => {
    return { id: parseInt(token[0]), name: 'Monster Block '.concat(parseInt(token[0]).toString()) }
  })
}
