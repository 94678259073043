import React from 'react'
// Assets
import sketches1 from '../assets/sketches1.png'
import sketches2 from '../assets/sketches2.png'
import sketches3 from '../assets/sketches3.png'
import sketches4 from '../assets/sketches4.png'

function BehindTheScenes() {
    return (
      <div className="container mx-auto mt-8 mb-24">
        <h1 className="font-cursive text-center uppercase text-5xl mb-4">
            Monster Sketches
        </h1>
        <p className="text-center mb-10">
          Behind the scenes with Zack (aka Gossip Goblin)
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-4 mb-8 px-4 md:px-8 lg:px-16 xl:px-32">
          <div className="flex justify-center">
            <img src={sketches2} alt="Sketches" className="h-96 md:h-112 lg:h-144 object-contain" />
          </div>
          <div className="flex justify-center">
            <img src={sketches3} alt="Sketches" className="h-96 md:h-112 lg:h-144 object-contain" />
          </div>
          <div className="flex justify-center">
            <img src={sketches4} alt="Sketches" className="h-96 md:h-112 lg:h-144 object-contain" />
          </div>
          <div className="flex justify-center">
            <img src={sketches1} alt="Sketches" className="h-96 md:h-112 lg:h-144 object-contain" />
          </div>
        </div>
      </div>
    )
}

export default BehindTheScenes
