import { useContractCall } from '@usedapp/core'
import { MonsterBricksInterface, MONSTER_BRICKS_CONTRACT_ADDRESS } from '../model/monsterBricksContract'

export default function useSalePaused(address) {
  const [value] = useContractCall({
    abi: MonsterBricksInterface,
    address: MONSTER_BRICKS_CONTRACT_ADDRESS,
    method: 'paused',
    args: address && []
  }) ?? [true]
  return !!value
}
