import { ChainId } from '@usedapp/core'

export default {
    rinkeby: {
        readOnlyChain: ChainId.Rinkeby,
        readOnlyUrls: {
            [ChainId.Rinkeby]: 'https://wild-purple-silence.rinkeby.quiknode.pro/e81f84579c1523facbe9316784a269f468238629/',
        },
        supportedChains: [
            ChainId.Rinkeby,
        ]
    },
    mainnet: {
        readOnlyChain: ChainId.Mainnet,
        readOnlyUrls: {
            [ChainId.Mainnet]: 'https://withered-withered-wildflower.discover.quiknode.pro/c1d8bdeefb9e0c7b71cded77ce2670b4568cabbd/',
        },
        supportedChains: [
            ChainId.Mainnet
        ]
    }
};
