import React from 'react'
import ReactImageAppear from 'react-image-appear'
import { PlusIcon } from '@heroicons/react/outline'
import loading from '../assets/loading.gif'

function SelectableBlock({ block, selectBlock, blockSubtitle }) {
    return (
      <>
        <li key={block.id.toString()} className="flex flex-col gap-4 w-full justify-center group">
          <div className="relative z-10 invisible group-hover:visible">
            <button className="absolute top-4 right-4 w-10 h-10 rounded-full bg-green-700 text-white text-4xl flex items-center justify-center"
                    onClick={() => selectBlock(block)}>
              <span>
                <PlusIcon className="text-white h-6 w-6"/>
              </span>
            </button>
          </div>
          <ReactImageAppear
              key={'selectable-' + block.id.toString()}
              src={"https://storageapi.fleek.co/monster-blocks-bucket/production/".concat(block.id.toString())}
              className="bg-white"
              loader={loading}
              loaderStyle={{ backgroundColor: "white", position: "absolute", top: 100 }}
              placeholderStyle={{ backgroundColor: "white" }}
          />
          <div className="flex flex-row justify-between items-center gap-2">
            <div className="flex flex-col gap-2">
              <p className="block text-xs font-medium text-gray-900 truncate pointer-events-none">{block.name}</p>
              <p className="block text-xs font-medium text-gray-500 pointer-events-none">{blockSubtitle}</p>
            </div>
          </div>
        </li>
      </>
    )
}

export default SelectableBlock
