import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

function TransactionStatusDialog({ subtitle, isShowing, setShowTxModal, transactionStatus, miningStatus, successStatus, rejectedStatus }) {
  return (
    <Transition.Root show={isShowing} as={Fragment}>
    <Dialog as="div" auto-reopen="true" className="fixed z-10 inset-0 overflow-y-auto" onClose={setShowTxModal}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="inline-block align-bottom bg-white border-2 border-yellow-300 rounded-xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
            <div className="w-full flex flex-col items-center text-center">
              { transactionStatus === 'mining' && miningStatus }
              { transactionStatus === 'success' && successStatus }
              { transactionStatus === 'failed' && rejectedStatus }

              <button
                type="button"
                className="absolute top-0 right-0 -mr-4 -mt-4 h-8 w-8 bg-black text-white text-center outline-none"
                onClick={() => setShowTxModal(false)}
              >
                𝖷
              </button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
  )
}

export default TransactionStatusDialog;
