import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import MonsterMarketAbi from '../abi/monsterMarket.json'
import contractConfig from '../contracts.config.js';

const MONSTER_MARKET_CONTRACT_ADDRESS = contractConfig['MonsterMarket']

const MonsterMarketInterface = new utils.Interface(MonsterMarketAbi)

function monsterMarketContract() {
  return new Contract(MONSTER_MARKET_CONTRACT_ADDRESS, MonsterMarketInterface)
}

export { MonsterMarketInterface, monsterMarketContract, MONSTER_MARKET_CONTRACT_ADDRESS }
