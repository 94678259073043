// Merkle Root: 0x9ecb5a4a5e15fff68bbc2e6136091cd1a7b3b6dbb78487ff78945ba9347468d9

export const merkleEntries = [
  ['0xb62326c18ecf52886f2ad8df53447b47ca0a9ed5',1],
  ['0xf2290d6f3564e12f851d808cab6a6a98f0250f73',1],
  ['0x28ead95628610b4ee91408cfe1c225c71ab6e7a8',4],
  ['0x333345727be2ec482baf99a25cb1765cb7b78de6',1],
  ['0xebd0a4c0da1f79de68cd53ab805c47e7b87b02b2',1],
  ['0xef04b36562cc1e5f95de2ec934ae04b4216dfb74',4],
  ['0xa2198b72ca77868a7d7ad87f42040065511b2899',1],
  ['0x86a41524cb61edd8b115a72ad9735f8068996688',1],
  ['0xff11577e9c1ad9c097cba1f805bd86ffd9a11c0f',6],
  ['0x07c578047dd6dea9a14c8b20301a18e491be30bc',9],
  ['0xa28e586e24e6d78d05e822188ddc118ac2fb034b',1],
  ['0xb7bff5fdca4ff1e7c696a2f7c9eeab9683b9c1ac',3],
  ['0x7cc696eeb48dc6acee89447168302a90c116bc34',4],
  ['0x90bfdecf82d06e4cd35b7ff8fe6bc0babb1cbc4e',2],
  ['0xf5a7080034806a6b5eaf119ac37923b0dd9536db',1],
  ['0x87c5ae90ad225004c7b58a4d1eb5daffc6b737ac',3],
  ['0x74be0af0bf7254328ddffc09425ff71d64a1a836',2],
  ['0xdaccd23613712452e2ac271bab0aea1d969b0881',1],
  ['0x255f7cb572651e3ce3af30fb8a38a25b945b8abe',1],
  ['0x652d7e1b706b10de0bfee179c1cc41df73d3ed34',1],
  ['0xc2f2a818e2dd93eb4348e52e5f3e1ab49de9ae5d',1],
  ['0xee7ad10fbf6bae8f53a7af42c78659a49ae3abdf',1],
  ['0xab0a2e764d48d16f4d332bd2ed66793fe46ca7eb',2],
  ['0x39292e0157ef646cc9ea65dc48f8f91cae009eae',1],
  ['0xe111fda134ff7e5da80d77d6e2f9cd7ef2a0be57',1],
  ['0x30282e114f4f41eeb1d93a443e2e9e8417e777c3',4],
  ['0xd70cf54d6481e5a21e11d0c319f357e05ff4dc2f',1],
  ['0x9023ef16968ffea318838a223ef2a79bd9f99f88',1],
  ['0x3f3dedd362020a229cca78d19d535d6971fd667e',1],
  ['0xa32c38646299818ccedc6401818c2e1639c39c08',1],
  ['0xc294cc5443c1255a20e808d0a24b2a4579baa5e3',2],
  ['0x7675291453daf025cef152bef7296d4ef9d72514',1],
  ['0x05a63759a1a5dd85e46ea6ab11700779d7279a37',1],
  ['0x8cd3674ade68d5d5067664586285bcc0cd644600',2],
  ['0xc7e8cede3d6bcb7dc279e000bbcb26dafd5b3b18',2],
  ['0xbff4021a901117a806afa22ef0aa23f39cda5b97',2],
  ['0x050ee024a0ffd8b9bd383271835b27dd66c3fec3',1],
  ['0xfe5573c66273313034f7ff6050c54b5402553716',2],
  ['0x80ae2dc0a16bd82771b5dd38ef442dc2a79e8172',1],
  ['0x54a2a9ece370a53d496ca7824aacb2163f1cd26a',1],
  ['0xeb035fcafa8786183bfa7a114ad01f0cdbfd32b7',1],
  ['0x43de3a240979a1922784cf26ed4e13953166a119',3],
  ['0x1612c87b4ed492b2d84243d5303b599f44acabb4',1],
  ['0x0a7669015fef82f5136c9a2e44e1ecbd2a5aec19',1],
  ['0x82c4707da8d867d6eeb2a4042ff9c21ddd41ec34',1],
  ['0x8f791f061d7f36dc07de081ad88f87d71be1585e',1],
  ['0x3bf826415da10cb25a9a8e8a9e01b97bfc8875ac',1],
  ['0xeb298223f82ecb1e8b21c9f0ca5e7ba7a98c732e',2],
  ['0x85aed3be608be2276efba293c04c4fad29f57eef',1],
  ['0xf3f050d61191ed22e35e968366c664f793b9720e',1],
  ['0x20335c504a4f0d8db934e9f77a67b55e6ae8e1e1',1],
  ['0xd5a9c4a92dde274e126f82b215fccb511147cd8e',1],
  ['0xe6a1c84806f3252b9db017fb48736c14f1b682f0',1],
  ['0xaa7a9d80971e58641442774c373c94aafee87d66',1],
  ['0xab48edd90bdf367d326d827758bacd2460c59d17',2],
  ['0x7f88b5750f8c1dd53112f683bb96e5551b103ac5',1],
  ['0xf555ea7a85c2cf13db640148e2d4c8a8027e8ef4',1],
  ['0x3055909429f637041b3e23d3fa357f98bd7ab418',1],
  ['0xc05c4f2bf8c629d6f9f674e6949b6fe54832764e',1],
  ['0x76f1815b6bbfb7105ae04fdcbfca1e6be47acb93',1],
  ['0x011ea68c15f4a8316da45c1e7844311cdd0ba149',1],
  ['0xbf237cc849f471149ff5fd0ecad0e455b9e71a74',1],
  ['0xa50b5cc2340b4bb4a6b761abc53b428a959f139e',1],
  ['0x2eb5e5713a874786af6da95f6e4deacedb5dc246',1],
  ['0x323f9505a521ea742a584b39f8341beed46b44a9',1],
  ['0xd9d23db5632262033ad9f73ac8fbba8d76d00188',1],
  ['0xac0c217960bc60438bdc544cb1676d927d5ebdbd',1],
  ['0x79a3303931dae1be588ed11f5d58a430c0c85ca3',1],
  ['0xeaf3720bcbc87dcd21c63e143d2a187b11c00e47',1],
  ['0xc93321cbf3b9f1098e13f9acdb2eacddeeab3e1a',1],
  ['0xcd0d4dfc11d7301ffcbe7c66e25b23c826139de4',1],
  ['0xe51e5e0530ae56c45b034455741f04163952609f',3],
  ['0x81fbd7a1f4ad93853b73437f909b54dd9e68f62d',2],
  ['0x6be55c75d61ecaab7edf7791c087858269760383',1],
  ['0x4b6760682191de7e476b801b7ab42d8e8a5b041a',1],
  ['0x6551c8dd85570ecd09fb7559022bd17795caae24',1],
  ['0x018edc98b3190dbb261e6ff3858c4ba59c71ba94',1],
  ['0xa3ed353b003df1d08d153b1f7989df8e35019ec8',1],
  ['0x4d0eeb7982f93a63142e1c37740fd833d065c289',1],
  ['0xb61960255440bd0473b21f38f985d2635d6ea58f',1],
  ['0xe10772c3c2e8879b13d5d2886ef8e9f9b95b83aa',2],
  ['0x8177598e08ee8199c160a48c7a0af31ab54bb59f',1],
  ['0x8d41954fe7c299764316a5fc017587a6aa663f36',1],
  ['0x11eb57d036223df08ba473fbdab0f54a54c948fa',1],
  ['0x578f9b95723ae641d6c9d37d5a47f42f3895eb02',1],
  ['0x50ef16a9167661dc2500ddde8f83937c1ba4cd5f',1]
]
