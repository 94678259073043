import React, { Fragment } from 'react'
import { Disclosure } from '@headlessui/react'
import { useEthers, useLookupAddress, shortenAddress } from '@usedapp/core'
import { HashLink as Link } from 'react-router-hash-link';
import { MenuIcon, XIcon } from '@heroicons/react/outline'

const navigation = [
  { name: 'Stackinator', href: '/stackinator' },
  { name: 'Sketches', href: '/sketches' },
  { name: 'Leaderboard', href: '/leaderboard' },
  { name: 'Monster Bricks', href: '/monster-bricks' },
  { name: 'Monster Market', href: '/monster-market' }
]

function Navigation() {
  const { activateBrowserWallet, deactivate, account } = useEthers()
  const ens = useLookupAddress()

  return (
    <div>
      <div className="hidden items-center 2xl:flex 2xl:flex-row 2xl:space-x-8 pr-8">
        { navigation.map((item) => (
          <div key={item.name}>
            <Link to={item.href}>{item.name}</Link>
          </div>
        ))}

        {account &&
          <div className="flex flex-row space-x-2">
            <div className="text-xl font-cursive bg-green-500 text-white py-2 px-4 rounded-xl">
              <p>{ens ?? shortenAddress(account)}</p>
            </div>
            <button onClick={deactivate} className="text-xl font-cursive bg-gray-600 text-white hover:text-gray-100 hover:bg-gray-700 py-2 px-4 rounded-xl"> Disconnect </button>
          </div>}
        {!account && <button onClick={activateBrowserWallet} className="text-xl font-cursive bg-green-500 text-white hover:text-gray-100 hover:bg-green-600 py-2 px-4 rounded-xl"> Connect </button>}
      </div>
      <div className="flex 2xl:hidden">
        <Disclosure as="nav">
          {({ open }) => (
            <>
              <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Open main menu</span>
                <span className="text-lg font-cursive uppercase mr-4">Menu</span>
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>

              <Disclosure.Panel className="origin-top-right absolute right-0 bg-white">
                <div className="border border-1 border-gray-300 rounded-md px-2 pt-2 pb-3 space-y-1">
                  <div className="pb-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={'/#'.concat(item.href)}
                        className='text-gray-800 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <hr/>
                  <div className="pt-2 space-y-2">
                    {account &&
                      <>
                        <div className="w-full text-xl font-cursive bg-green-500 text-white py-2 px-4 rounded-xl">
                          <p>{ens ?? shortenAddress(account)}</p>
                        </div>
                        <button onClick={deactivate} className="w-full text-xl font-cursive bg-gray-600 text-white hover:text-gray-100 hover:bg-gray-700 py-2 px-4 rounded-xl"> Disconnect </button>
                      </>
                    }
                    {!account && <button onClick={activateBrowserWallet} className="w-full text-xl font-cursive bg-green-500 text-white hover:text-gray-100 hover:bg-green-600 py-2 px-4 rounded-xl"> Connect </button>}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}

export default Navigation
