import React from 'react';
import ReactDOM from 'react-dom';
import { DAppProvider } from '@usedapp/core'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import networkConfig from './networks.config.js';

let network = 'rinkeby';
switch(process.env.REACT_APP_ENVIRONMENT) {
  case 'production':
    network = 'mainnet';
  break;
  case 'staging':
    network = 'rinkeby';
  break;
}

let config = networkConfig[network];

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
