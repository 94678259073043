import { useContractCalls } from '@usedapp/core';
import { MonsterBlockInterface, MONSTER_BLOCK_CONTRACT_ADDRESS } from '../model/monsterBlockContract'
import { MonsterMarketInterface, MONSTER_MARKET_CONTRACT_ADDRESS } from '../model/monsterMarketContract'

export default function useMonsterMarket(account) {
  const blockContract = {
    abi: MonsterBlockInterface,
    address: MONSTER_BLOCK_CONTRACT_ADDRESS
  };

  const marketContract = {
    abi: MonsterMarketInterface,
    address: MONSTER_MARKET_CONTRACT_ADDRESS
  };

  let result = useContractCalls(
    [
      {
        method: 'balanceOf',
        args: [MONSTER_MARKET_CONTRACT_ADDRESS],
        ...blockContract
      },
      account && {
        method: 'balanceOf',
        args:  [account],
        ...marketContract
      },
      account && {
        method: 'isApprovedForAll',
        args:  [account, MONSTER_MARKET_CONTRACT_ADDRESS],
        ...blockContract
      }
    ]
  ) ?? [[-1], [-1], [false]];

  let poolBalance = (result && result[0] ? parseInt(result[0][0]) : -1)
  let userMarketBalance = (result && result[1] ? parseInt(result[1][0]) : -1)
  let marketApproved = (result && result[2] ? !!result[2][0] : false)

  let tokenIdCalls = []
  for (let i = 0; poolBalance && i < parseInt(poolBalance); i++) {
    tokenIdCalls.push({
      method: 'tokenOfOwnerByIndex',
      args: [MONSTER_MARKET_CONTRACT_ADDRESS, i],
      ...blockContract
    })
  }

  let tokenResult = useContractCalls(tokenIdCalls) ?? []
  let poolTokens = tokenResult.filter((token) => {
    return !!token
  }).map((token) => {
    return { id: parseInt(token[0]), name: 'Monster Block '.concat(parseInt(token[0]).toString()) }
  }).sort((firstToken, secondToken) => {
    return firstToken.id - secondToken.id
  })

  let isLoaded = (poolBalance === 0 || (poolBalance > 1 && poolTokens.length > 1))

  return {
    isLoaded,
    userMarketBalance,
    poolBalance,
    poolTokens,
    marketApproved
  }
}
