import rinkebyJSON from './abi/deployments/rinkeby.json';
import mainnetJSON from './abi/deployments/mainnet.json';

const networkConfigs = {
  rinkeby: rinkebyJSON,
  mainnet: mainnetJSON
};

let network = 'rinkeby';
switch(process.env.REACT_APP_ENVIRONMENT) {
  case 'production':
    network = 'mainnet';
  break;
  case 'staging':
    network = 'rinkeby';
  break;
}

export default networkConfigs[network];
