import React from 'react';
import {
  HashRouter,
  Switch,
  Route
} from "react-router-dom"
import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from './Views/Home'
import Stackinator from './Views/Stackinator'
import BehindTheScenes from './Views/BehindTheScenes'
import Leaderboard from './Views/Leaderboard'
import MonsterBricks from './Views/MonsterBricks'
import MonsterMarket from './Views/MonsterMarket'
import MonsterMadness from './Views/MonsterMadness'

function App() {
  return (
    <div className="relative pb-10 min-h-screen">
      <HashRouter>

        <Header />

        <div className="px-4 py-8">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/stackinator">
            <Stackinator />
          </Route>
          <Route path="/sketches">
            <BehindTheScenes />
          </Route>
          <Route path="/leaderboard">
            <Leaderboard />
          </Route>
          <Route path="/monster-bricks">
            <MonsterBricks />
          </Route>
          <Route path="/monster-market">
            <MonsterMarket />
          </Route>
        </Switch>
        </div>

        <Footer />

      </HashRouter>
    </div>
  );
}

export default App;
