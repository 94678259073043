function ErrorComponent({ showError, message }) {
  return (
    <>
    { showError && (
      <div className="my-2 py-2 px-4 bg-red-500 text-sm items-center text-red-100 leading-none items-center lg:rounded-full flex lg:inline-flex" role="alert">
        <span className="uppercase pr-2">Error:</span>
        <span className="text-left">{message}</span>
      </div>
    )}
    </>
  )
}

export default ErrorComponent
