import { merkleEntries } from '../model/merkleEntries';
import { utils } from 'ethers'

export default function useMerkleBalance(address) {
  const addresses = merkleEntries.filter(entry => utils.getAddress(entry[0]) === address)

  if (addresses.length > 0) {
    return addresses[0][1]
  } else {
    return 0
  }
}
