import React, { Fragment, useState, useEffect } from 'react'
import { useEthers, useContractFunction } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'
import { ethers } from 'ethers'
import { MerkleTree } from 'merkletreejs';
import { merkleEntries } from '../model/merkleEntries';
import keccak256 from 'keccak256';
import useMerkleBalance from '../hooks/useMerkleBalance';
import useSalePaused from '../hooks/useSalePaused';
import useLatestTokenClaimed from '../hooks/useLatestTokenClaimed';
import { monsterBricksContract, MONSTER_BRICKS_CONTRACT_ADDRESS, MonsterBricksInterface } from '../model/monsterBricksContract'
import { estimateGas } from '../calls/estimateGas'
import { Dialog, Transition } from '@headlessui/react'
// Assets
import txRejected from '../assets/tx-rejected.png'
import txMining from '../assets/tx-mining.gif'
import currentBrick from '../assets/current-brick.jpg'
import etherscan from '../assets/etherscan.svg'

function MonsterBricks() {
    const { account, library, activateBrowserWallet } = useEthers()
    const salePaused = useSalePaused(account)
    const merkleBalance = useMerkleBalance(account)
    const latestTokenClaimed = useLatestTokenClaimed(account)
    const [merkleProof, setMerkleProof] = useState(null)
    const [transactionStatus, setTransactionStatus] = useState('empty')
    const [lastTransaction, setLastTransaction] = useState(null)

    const [showTxModal, setShowTxModal] = useState(false)

    const hashToken = (account, quantity) => {
      return Buffer.from(ethers.utils.solidityKeccak256(['address', 'uint256'], [account, quantity]).slice(2), 'hex')
    }

    const { send, state } = useContractFunction(monsterBricksContract(), 'claimBrick')

    useEffect(() => {
      if (state && state.status === "Mining") {
        setShowTxModal(true)
        setTransactionStatus('mining')
        setLastTransaction(state.transaction.hash)
      } else if (state && state.status === "Success" && state.transaction.hash === lastTransaction) {
        setTransactionStatus('success')
        setLastTransaction(null)
      } else if (state && (state.status === "Fail" || state.status === "Exception")) {
        setTransactionStatus('failed')
        setLastTransaction(null)
      }
    }, [state]);

    useEffect(() => {
      // const mt = new MerkleTree(merkleEntries.map(token => hashToken(...token)), keccak256, { sortPairs: true })
      // console.log('Root:')
      // console.log(mt.getHexRoot())

      if (account && merkleBalance) {
        const merkleTree = new MerkleTree(merkleEntries.map(token => hashToken(...token)), keccak256, { sortPairs: true })
        let mp = merkleTree.getHexProof(hashToken(account, merkleBalance))
        setMerkleProof(mp)
      } else {
        setMerkleProof(null)
      }
    }, [account, merkleBalance])

    const claimBrick = async () => {
      try {
        const gasEstimation = estimateGas(
          new Contract(MONSTER_BRICKS_CONTRACT_ADDRESS, MonsterBricksInterface, library),
          'claimBrick',
          [account, merkleBalance, merkleProof, { from: account }],
          2000
        )

        send(account, merkleBalance, merkleProof, { gasLimit: gasEstimation })
      } catch (e) {
        console.error(e)
      }
    }

    return (
        <div className="container mx-auto">

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 gap-y-20 md:gap-16 lg:gap-24 my-16 px-4 md:px-8 lg:px-16 xl:px-32">
            <div className="flex flex-col items-start self-center space-y-2">
              <h1 className="font-cursive uppercase text-6xl">
                Monster Bricks
              </h1>
              <a href={"https://etherscan.io/address/".concat(MONSTER_BRICKS_CONTRACT_ADDRESS)} target="_blank" rel="noreferrer"
                 className="flex flex-row gap-0 items-center pb-4">
                <img src={etherscan} alt="Etherscan" className="h-12 p-3 object-contain" />
                <span className="text-xs text-gray-600">
                  Monster Bricks Smart Contract
                </span>
              </a>
              <div className="pb-4">
                At the end of each week we will be selecting
                a specific brick and our resident badass artist Gossip Goblin will be creating
                Profile Pictures (PFP) characters/versions of those bricks! {' '}
                <span className="italic">(To recap: one Monster Block is made up of three Monster Bricks.)</span>
              </div>
              <div className="pb-4">
                These will be available to mint for a limited time from this page
                after the snapshot of holders is taken a few days after announcement.
                These will be {' '}
                <span className="font-bold underline">free to mint</span> (plus gas) for
                anyone who holds the selected brick for that period when the snapshot is taken.  
              </div>
              <div className="pb-4">
                We will be running this program for 12 months beginning October 2021!
                Only thirty Monster Brick PFPs will be created in total.
              </div>
              <div className="pb-4">
                Join the Discord for more information and to ask us any questions!
              </div>
              <div className="grid grid-cols-2 gap-4">
                <a href="https://discord.gg/bYU7ApuCnm" target="_blank" rel="noreferrer"
                   className="w-full text-center text-lg xl:text-xl bg-blurple text-white font-cursive hover:text-gray-100 hover:bg-blue-700 py-4 px-4 rounded-lg">
                Join The Discord
                </a>

                <a href="https://opensea.io/collection/monsterbricks" target="_blank" rel="noreferrer"
                   className="w-full text-center text-lg xl:text-xl bg-blue-400 text-white font-cursive hover:text-gray-100 hover:bg-blue-500 py-4 px-4 rounded-lg">
                Buy on OpenSea
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center justify-center">
              <div className="flex flex-col gap-y-4 border-2 border-yellow-300 rounded-md bg-white items-center text-center">
                <div className="w-full bg-yellow-300 px-16 py-6">
                  <span className="font-cursive uppercase text-4xl">
                    Claim Your Brick(s)
                  </span>
                </div>
                { account && salePaused &&
                  <>
                    <div className="px-8 pt-12">
                      Claiming is currently paused.
                    </div>
                    <div className="px-8 pb-16">
                      Please check the Discord for timing announcements.
                    </div>
                  </>
                }
                { (!account || !salePaused) &&
                  <>
                    <div className="px-8">
                      <p className="mb-2">We have arrived at the end!</p>
                      <p>
                        Our thirtieth Monster Brick is the {' '}
                        <span className="font-bold">Happy Meal Crown</span>.
                      </p>
                    </div>
                    <img src={currentBrick} alt="Current Brick" className="px-8 h-80 object-contain" />
                    { latestTokenClaimed < 30 &&
                      <>
                        { account && merkleBalance > 0 &&
                          <>
                            <div className="px-8">
                              You can claim up to {' '}
                              <span className="font-bold underline">{merkleBalance}</span> Crown brick{merkleBalance > 1 && 's'}.
                            </div>
                            <div className="px-8">
                              Claiming is open until September 12th, 2022.
                            </div>
                          </>
                        }
                        { account && merkleBalance === 0 &&
                          <div className="px-8">
                            Sorry, you are not eligible to claim a Crown brick.
                          </div>
                        }
                      </>
                    }

                    { account && latestTokenClaimed === 30 &&
                      <div className="px-8">
                        You have already claimed your Crown(s) 🎉
                      </div>
                    }

                    <div className="pb-4 px-8">
                      { !account &&
                        <button onClick={activateBrowserWallet}
                                className="mb-2 w-80 text-3xl bg-green-500 text-white font-cursive uppercase hover:text-gray-100 hover:bg-green-600 py-4 px-6 rounded-lg">
                         Connect Wallet
                        </button>
                      }

                      { account && merkleBalance > 0 && latestTokenClaimed < 30 &&
                        <button onClick={claimBrick}
                                className="mb-2 w-80 text-3xl bg-green-500 text-white font-cursive uppercase hover:text-gray-100 hover:bg-green-600 py-4 px-6 rounded-lg">
                          Claim!
                        </button>
                      }
                    </div>
                  </>
                }
              </div>
            </div>
          </div>

          <Transition.Root show={showTxModal} as={Fragment}>
            <Dialog as="div" auto-reopen="true" className="fixed z-10 inset-0 overflow-y-auto" onClose={setShowTxModal}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white border-2 border-yellow-300 rounded-xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
                    <div className="w-full flex flex-col items-center text-center">
                      <div className="w-full bg-yellow-300 rounded-t-xl px-16 py-6">
                        <h3 className="font-cursive uppercase text-4xl">
                          { transactionStatus === 'mining' && <>Minting...</> }
                          { transactionStatus === 'success' && <>Confirmed!</> }
                          { transactionStatus === 'failed' && <>Transaction Rejected</> }
                        </h3>
                      </div>
                      <div className="text-md whitespace-pre-wrap py-8">
                        { transactionStatus === 'mining' &&
                          <>
                            <span>
                              A virtual gnome in Ethereum hyperspace is processing your transaction.
                              Please hold the line.
                            </span>
                            <img src={txMining} alt="Transaction Mining" className="mx-auto py-4 h-48 object-contain" />
                          </>
                        }
                        { transactionStatus === 'success' &&
                          <>
                            Check out your new brick{ merkleBalance > 1 && 's'} on
                            {' '}<a href={"https://opensea.io/".concat(account).concat('?search[sortBy]=LAST_TRANSFER_DATE&search[sortAscending]=false')} target="_blank" rel="noreferrer" className="outline-none font-work font-semibold text-md text-ape-blue hover:text-blue-900">OpenSea</a>.
                          </>
                        }
                        { transactionStatus === 'failed' &&
                          <>
                            <img src={txRejected} alt="Transaction Rejected" className="h-48 object-contain" />
                            <span>
                              Looks like your transaction was rejected. Please try again! Please try again.
                            </span>
                          </>
                        }
                      </div>
                      <button
                        type="button"
                        className="absolute top-0 right-0 -mr-4 -mt-4 h-8 w-8 bg-black text-white text-center outline-none"
                        onClick={() => setShowTxModal(false)}
                      >
                        𝖷
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
    )
}

export default MonsterBricks
