import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import MonsterBricksAbi from '../abi/monsterBricks.json'
import contractConfig from '../contracts.config.js';

const MONSTER_BRICKS_CONTRACT_ADDRESS = contractConfig['MonsterBricks']

const MonsterBricksInterface = new utils.Interface(MonsterBricksAbi)

function monsterBricksContract() {
  return new Contract(MONSTER_BRICKS_CONTRACT_ADDRESS, MonsterBricksInterface)
}

export { MonsterBricksInterface, monsterBricksContract, MONSTER_BRICKS_CONTRACT_ADDRESS }
